"use client";

import {
  OrganizationSwitcher,
  SignedIn,
  SignedOut,
  SignInButton,
  useAuth,
  useOrganization,
  useOrganizationList,
  UserButton,
} from "@clerk/nextjs";
import { Box, Button, Container, Group } from "@mantine/core";
import classnames from "classnames";
import { usePathname } from "next/navigation";

import AppLogo from "@/components/AppLogo/AppLogo";
import { TransitionLink } from "@/components/TransitionLink/TransitionLink";
import { addClerkTermsDisclaimer } from "@/utils/clientHelpers";

import { ColorSchemeToggle } from "../ColorSchemeToggle/ColorSchemeToggle";

import classes from "./Header.module.css";

const personalLinks = [
  { link: "/", label: "Home" },
  { link: "/my-tennis-profile", label: "My Tennis Profile", isPrivate: true, role: "" },
  { link: "/play-tennis", label: "Play Tennis" },
  { link: "/get-togethers", label: "Get-Togethers" },
  { link: "/blog", label: "Blog" },
  { link: "/about-us", label: "About Us" },
];

const orgLinks = [
  { link: "/admin", label: "Admin", isPrivate: true, role: "org:admin" },
  { link: "/admin/events", label: "Events", isPrivate: true, role: "org:admin" },
];

interface HeaderProps {
  darkBackground?: boolean;
}

export function Header(props: HeaderProps) {
  const { darkBackground } = props;

  const pathname = usePathname();
  const { userMemberships } = useOrganizationList({ userMemberships: true });

  return (
    <header className={classes.header}>
      <Container className={classes.inner}>
        <AppLogo className={classes.logo} />

        <Box className={classes.links} visibleFrom="mobile">
          <NavigationMenu darkBackground={darkBackground} />
        </Box>

        <Box className={classes.links} hiddenFrom="mobile">
          <NavigationMenu darkBackground={darkBackground} take={2} />
        </Box>

        <Group wrap="nowrap">
          <SignedIn>
            {(userMemberships?.count ?? 0) >= 1 ? (
              <OrganizationSwitcher
                afterSelectOrganizationUrl={orgLinks[0].link}
                afterSelectPersonalUrl={personalLinks[0].link}
                appearance={{
                  elements: {
                    organizationSwitcherTrigger: {
                      padding: 0,
                      backgroundColor: "initial",

                      ".cl-userPreviewTextContainer": { display: "none" },
                      ".cl-organizationPreviewTextContainer": { display: "none" },
                    },
                    organizationSwitcherTriggerIcon: { display: "none" },
                    avatarBox: {
                      width: 28,
                      height: 28,
                      borderRadius: "50%",
                    },
                  },
                }}
              />
            ) : (
              <UserButton userProfileMode="modal" defaultOpen={false} />
            )}
          </SignedIn>

          <SignedOut>
            <SignInButton mode="modal" forceRedirectUrl={pathname} signUpForceRedirectUrl={pathname}>
              <Button size="compact-md" onClick={addTermsDisclaimer}>
                Sign in
              </Button>
            </SignInButton>
          </SignedOut>

          <ColorSchemeToggle />
        </Group>
      </Container>

      <Box className={classes.links} hiddenFrom="mobile">
        <NavigationMenu darkBackground={darkBackground} skip={2} />
      </Box>
    </header>
  );
}

function NavigationMenu({ darkBackground, skip, take }: { darkBackground?: boolean; skip?: number; take?: number }) {
  const pathname = usePathname();
  const { isSignedIn } = useAuth();
  const { organization } = useOrganization();
  const { has } = useAuth();

  const links = organization ? orgLinks : personalLinks;

  return (
    <Group
      gap={0}
      justify="flex-end"
      className={classnames(classes.mainLinks, darkBackground && classes.mainLinksDark)}
    >
      {links
        .filter((item) => !item.isPrivate || isSignedIn)
        .filter((item) => !item.role || has?.({ role: item.role }))
        .slice(skip ?? 0, (skip ?? 0) + (take ?? links.length))
        .map((item) => (
          <TransitionLink
            href={item.link}
            key={item.link}
            className={classes.mainLink}
            data-active={
              pathname === item.link || (item.link !== links[0].link && pathname?.startsWith(item.link)) || undefined
            }
          >
            {item.label}
          </TransitionLink>
        ))}
    </Group>
  );
}

function addTermsDisclaimer() {
  const modalNode = document.querySelector<HTMLElement>(".cl-modalContent .cl-signUp-root");
  if (!modalNode) {
    setTimeout(() => addTermsDisclaimer(), 100);
    return;
  }

  addClerkTermsDisclaimer(modalNode);
}
