import { TransitionLink } from "@/components/TransitionLink/TransitionLink";

export interface AppLogoProps extends React.ComponentPropsWithoutRef<"svg"> {}

const AppLogo = ({ ...svgProps }: AppLogoProps) => (
  <TransitionLink href="/" aria-label="AD-IN Logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 149.999998"
      role="img"
      aria-label="AD-IN Logo"
      color="white"
      fill="white"
      {...svgProps}
    >
      <title>AD-IN Logo</title>

      <defs>
        <clipPath id="a">
          <path d="M86.559 34.531h24.75v56.25h-24.75Zm0 0" />
        </clipPath>
        <clipPath id="b">
          <path d="M88.613 39.04H98V49h-9.387Zm0 0" />
        </clipPath>
        <clipPath id="c">
          <path d="M99 50h9.863v9.29H99Zm0 0" />
        </clipPath>
        <clipPath id="d">
          <path d="M88.613 39.04h20.25v20.25h-20.25Zm0 0" />
        </clipPath>
      </defs>
      <path fill="#fff" d="M-15-15h180v180H-15z" />
      <path fill="#fff" d="M-15-15h180v180H-15z" />
      <path fill="#0d7948" d="M-15-15h180v180H-15z" />
      <path
        fill="#fff"
        d="M32.232 81.39H15.857l-3.531 7.86H9.09l13.453-29.406h3.063L39.044 89.25h-3.28Zm-1.125-2.515-7.063-15.797-7.046 15.797Zm12.801-19.031h11.985c3.101 0 5.844.625 8.219 1.875 2.382 1.25 4.234 2.992 5.546 5.218 1.32 2.22 1.985 4.758 1.985 7.61 0 2.855-.664 5.398-1.985 7.625-1.312 2.23-3.164 3.969-5.546 5.219-2.375 1.242-5.118 1.859-8.22 1.859H43.909Zm11.813 26.718c2.57 0 4.82-.507 6.75-1.53 1.937-1.02 3.437-2.43 4.5-4.235 1.062-1.813 1.594-3.895 1.594-6.25 0-2.352-.532-4.43-1.594-6.235-1.063-1.8-2.563-3.21-4.5-4.234-1.93-1.031-4.18-1.547-6.75-1.547h-8.703v24.031Zm80.468-26.718V89.25h-2.563L114.72 65.39v23.86h-3.11V59.844h2.563l18.953 23.86v-23.86Zm0 0"
      />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M98.93 62.719c-5.903 0-10.692-5.856-10.692-13.074 0-7.22 4.79-13.075 10.692-13.075 5.906 0 10.695 5.856 10.695 13.075 0 7.218-4.79 13.074-10.695 13.074Zm3.437 2.074-.012.02c-.007.015-.02.027-.03.042-1.56 2.215-2.41 6.485-2.532 7.114 0 .02-.004.035-.008.054l-.008.028a.879.879 0 0 1-1.691 0l-.008-.035-.008-.028c-.566-2.668-2.566-7.195-2.566-7.195v-.004a.488.488 0 0 1-.067-.25c0-.289.239-.527.536-.527.015 0 .027.004.043.008v-.004h5.836v.004c.015-.004.03-.008.046-.008.297 0 .536.238.536.527a.488.488 0 0 1-.067.25Zm8.93-15.148c0-8.352-5.535-15.122-12.367-15.122-6.828 0-12.368 6.77-12.368 15.122 0 5.792 2.668 10.828 6.583 13.367l-.004-.004s3.406 2.398 3.906 10.238v17.527h3.77V72.93c.027-1.254.398-6.078 4.39-10.254l-.004.004c3.649-2.633 6.094-7.485 6.094-13.035"
        />
      </g>
      <path fill="none" stroke="#fff" d="M0 .501h18.438" transform="matrix(.73282 0 0 .75 77.25 77.061)" />
      <g clipPath="url(#b)">
        <path
          fill="#f5f8ae"
          d="M97.773 40.055c0-.32-.023-.637-.062-.953a9.63 9.63 0 0 0-1.695.328c-.555.156-1.09.36-1.614.61a9.925 9.925 0 0 0-2.805 1.984c-.41.406-.78.847-1.113 1.316a10.15 10.15 0 0 0-.87 1.488 9.92 9.92 0 0 0-.61 1.613 9.63 9.63 0 0 0-.328 1.696 8.037 8.037 0 0 0 4.07-.563 8.12 8.12 0 0 0 2.637-1.765 8.095 8.095 0 0 0 1.765-2.641c.207-.492.36-1 .465-1.523a8.345 8.345 0 0 0 .16-1.59Zm0 0"
        />
      </g>
      <g clipPath="url(#c)">
        <path
          fill="#f5f8ae"
          d="M99.809 58.371c-.004.32.02.64.058.957a9.63 9.63 0 0 0 1.695-.328c.555-.156 1.09-.36 1.614-.61a10.15 10.15 0 0 0 1.488-.87 10.23 10.23 0 0 0 3.91-5.532c.16-.558.27-1.12.328-1.695a7.81 7.81 0 0 0-.953-.063 8.345 8.345 0 0 0-1.59.16 8.102 8.102 0 0 0-1.527.465 8.1 8.1 0 0 0-1.406.75 8.296 8.296 0 0 0-2.242 2.247 8.12 8.12 0 0 0-1.375 4.52Zm0 0"
        />
      </g>
      <g clipPath="url(#d)">
        <path
          fill="#f5f8ae"
          d="M107.95 48.195c.323 0 .66.024.976.043a10.379 10.379 0 0 0-.934-3.363 9.734 9.734 0 0 0-.883-1.512 9.986 9.986 0 0 0-1.129-1.343 9.97 9.97 0 0 0-1.34-1.125 9.734 9.734 0 0 0-1.511-.883 10.356 10.356 0 0 0-3.363-.934c.02.317.043.652.043.977a10.174 10.174 0 0 1-2.988 7.191 10.163 10.163 0 0 1-7.192 2.984c-.324 0-.66-.02-.977-.039a10.379 10.379 0 0 0 .934 3.364c.25.53.543 1.035.883 1.511.336.48.71.926 1.129 1.34.414.414.859.793 1.34 1.13a10.055 10.055 0 0 0 3.152 1.491c.566.157 1.14.266 1.722.325a16.545 16.545 0 0 1-.039-.98c0-.669.067-1.329.196-1.985a10.225 10.225 0 0 1 1.523-3.664 9.974 9.974 0 0 1 1.266-1.54 10.163 10.163 0 0 1 5.207-2.788 10.551 10.551 0 0 1 1.984-.2Zm0 0"
        />
      </g>
    </svg>
  </TransitionLink>
);

export default AppLogo;
