"use client";

import { Button, Paper, TextInput, Title, useComputedColorScheme } from "@mantine/core";
import Script from "next/script";
import { useRef, useState } from "react";

import { env } from "@/env-client";
import { newsletterSubscribePublic } from "@/serverActions/blog";
import { postServerAction } from "@/utils/clientHelpers";

import classes from "./NewsletterSignUp.module.css";

type RenderParameters = {
  sitekey: string;
  execution?: "render" | "execute";
  appearance?: "always" | "execute" | "interaction-only";
  "response-field"?: boolean;
  size?: "normal" | "compact";
  theme?: "light" | "dark";
  language?: string;
  callback?: (token: string) => void;
  "error-callback"?: () => void;
  "before-interactive-callback"?: () => void;
};

declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
    turnstile: {
      render: (container: string | HTMLElement, params?: RenderParameters) => void;
      execute: (container: string | HTMLElement, params?: RenderParameters) => void;
      getResponse: (container: string | HTMLElement) => string;
      reset: (container: string | HTMLElement) => void;
    };
  }
}

export default function NewsletterSignUp() {
  const colorScheme = useComputedColorScheme();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [turnstileNeeded, setTurnstileNeeded] = useState(false);
  const [turnstileRendered, setTurnstileRendered] = useState(false);
  // const [turnstileInteractive, setTurnstileInteractive] = useState(false);

  return (
    <form
      ref={formRef}
      action={async () => {
        await postServerAction(
          newsletterSubscribePublic,
          {
            email,
            turnstileResponse: window.turnstile?.getResponse("#newsletter-turnstile"),
          },
          {
            setIsLoading,
            successNotification: "Congrats! Thanks for signing up for the AD-IN newsletter.",
            errorNotification: "Something went wrong when joining, please try again.",
          },
        );
      }}
      style={{
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <Paper
        withBorder
        shadow="md"
        p={30}
        radius="md"
        style={{
          margin: "0 auto",
          maxWidth: 620,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          gap: 20,
        }}
      >
        <Title order={4} style={{ fontWeight: 400, color: "var(--mantine-color-text)", whiteSpace: "nowrap" }}>
          Sign up for our newsletter
        </Title>

        <TextInput
          type="email"
          required
          placeholder="enter your email"
          onChange={(event) => {
            setEmail(event.currentTarget.value);
            setTurnstileNeeded(true);
          }}
          style={{ flexGrow: 1 }}
        />

        <Button
          type="submit"
          loading={isLoading}
          onClick={(event: React.PointerEvent<HTMLButtonElement>) => {
            event.preventDefault();

            if (!formRef.current?.reportValidity()) {
              return;
            }

            setIsLoading(true);

            if (!turnstileRendered) {
              setTurnstileRendered(true);

              window.turnstile.render("#newsletter-turnstile", {
                // Testing keys. See https://developers.cloudflare.com/turnstile/reference/testing
                // sitekey: "3x00000000000000000000FF", // Forces an interactive challenge
                // sitekey: "2x00000000000000000000BB", // Always blocks
                sitekey: env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY,

                execution: "execute",
                appearance: "interaction-only",
                "response-field": false,
                size: "normal",
                theme: colorScheme,
                // "before-interactive-callback": () => setTurnstileInteractive(true),
                callback: () => {
                  formRef.current?.requestSubmit();

                  // Reset for next usage
                  window.turnstile.reset("#newsletter-turnstile");
                },
                "error-callback": () => setIsLoading(false),
              });
            }

            window.turnstile.execute("#newsletter-turnstile");
          }}
        >
          Sign up
        </Button>

        {turnstileNeeded ? (
          <>
            <div id="newsletter-turnstile" className={classes.turnstile} />
            <Script src="https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit" async defer />
          </>
        ) : null}
      </Paper>
    </form>
  );
}
